<template>
	<div id="splash">
		<div class="bottom">
			<TextKR ref="title" kr="김밥" class="title" :x="5">
				Kimbapp
			</TextKR>
			<h4>
				The delicious way to learn Korean!
			</h4>
		</div>
	</div>
</template>

<script>

import TextKR from '@/components/TextKR'
import router from '@/router'

export default {
	name: 'Home',
	components: {
		TextKR
	},
	mounted () {
		var src = '/img/views/splash/background-top.jpg'
		var image = new Image()
		image.addEventListener('load', () => {
			this.$el.style.backgroundImage = 'url(' + src + ')'
			setTimeout(this.goHome, 1000)
		})
		image.src = src
	},
	methods: {
		goHome () {
			this.$refs.title.hide()
			router.push('/home/')
		}
	}
}
</script>

<style lang="stylus" scoped>
#splash
	background-repeat no-repeat
	background-size 100% auto
	min-height 100%
	height 100%
	h4
		color #828282
		margin-top 18px
		user-select none
		font-family: Neue Montreal;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0em;

	.bottom
		position absolute
		bottom 150px
		left 0
		width 100%
</style>
